import React, { Component } from 'react'
import Title from '../components/title'
import Input from '../components/input'
import Select from '../components/select'
import Button from '../components/button'
import Checkbox from '../components/checkbox'
import { getCountryList, getStatesList, getProvincesList, getHobbies, getLists } from "../services/util";
import NewLayout from '../layout/newLayout'
import styles from '../styles/scss/pages/mailingPreferences.module.scss'
import SEO from "../components/seo"
import { Formik, FieldArray } from "formik"
import { isArray as _isArray, find as _find, isEmpty as _empty, filter as _filter, isDate as _isDate } from "lodash"
import PageContents from '../components/pageContents'
import cn from "classnames"
import { getEmailPreferences } from "../services/getEmailPreferences"
import moment from "moment"
import { setEmailPreferences } from '../services/setEmailPreferences'

export default class Index extends Component {

	state = {
		submitted: false,
		selectedCountry: null,
		selectedState: null,
		subscriber: null,
		processing: false,
		hobbiesForm: [],
		listsForm: [],
		subscriberKey: null,
	};

	countries = getCountryList();
	provinces = getProvincesList();
	states = getStatesList();
	hobbies = getHobbies();
	lists = getLists();

	initialValues = {
		firstname: "",
		lastname: "",
		email: "",
		phone: "",
		personbirthdate: "",
		personmailingcountry: "",
		personmailingstate: "",
		personmailingpostalcode: "",
		hobbies: [],
		lists: [],
		optOut: false
	}

	componentDidMount() {
		var subscriberKey = null
		if (this.props.location.search.length > 0 && this.props.location.search.indexOf('subscriberKey') > -1) {
			subscriberKey = this.props.location.search.replace('?', '').split('&')[0].split('=')[1]
			this.setState({
				subscriberKey: subscriberKey
			})
		}
		if (subscriberKey !== null && this.state.subscriber === null) {
			getEmailPreferences(subscriberKey).then((result) => {
				if (_empty(result.data)) {
					this.setState({
						subscriber: undefined
					})
				} else {
					// extract hobbies
					const listOfHobbies = [
						'atv_off_road_enthusiast__c',
						'auto_motorcycle_enthusiast__c',
						'boating_sailing__c',
						'auto_racing__c',
						'crafting_painting__c',
						'cycling__c',
						'diyer_home_improvement__c',
						'domestic_director__c',
						'fishing__c',
						'gardening__c',
						'metal_work__c',
						'mountain_biking__c',
						'outdoor_enthusiast__c',
						'sports_enthusiast__c',
					];
					const listOfLists = [
						'wd_40_email_list_brand__c',
						'wd_40_email_list_bike__c',
						'wd_40_co_newsletter_sign_up__c'
					];

					let selectedHobbies = _filter(this.hobbies, (hobby) => {
						const attributeToValidate = Object.keys(result.data).find(
							(key) => {

								return key === hobby.field
							}
						);
						if (attributeToValidate !== undefined &&
							listOfHobbies.includes(attributeToValidate) &&
							result.data[attributeToValidate] === 'True') {
							return true;
						}
						return false;
					}).map((hobby) => {
						return hobby.value.replace(/\s/g, "")
					})
					// extract lists
					let selectedLists = this.lists.filter((list) => {
						const attributeToValidate = Object.keys(result.data).find(
							(key) => {

								return key === list.field
							}
						);
						if (attributeToValidate !== undefined &&
							listOfLists.includes(attributeToValidate) &&
							result.data[attributeToValidate] === 'True') {

							return true;
						}
						return false;

					}).map((list) => {
						return list.id;
					})

					// set form values
					this.initialValues = {
						firstname: result.data.firstname,
						lastname: result.data.lastname,
						email: result.data.email,
						phone: result.data.phone,
						personbirthdate: moment(result.data.personbirthdate, "M/D/YYYY HH:mm:ss A").format("YYYY-MM-DD"),
						personmailingcountry: result.data.personmailingcountry,
						personmailingstate: result.data.personmailingstate,
						personmailingpostalcode: result.data.personmailingpostalcode,
						hobbies: selectedHobbies,
						lists: selectedLists,
						optOut: result.data.opt_in !== "True" ? true : false
					}
					const newValues = selectedHobbies;
					this.setState({
						listsForm: selectedLists,
						hobbiesForm: newValues,
						selectedCountry: _find(this.countries, { value: result.data.personmailingcountry }),
					})
					this.setState({
						subscriber: result.data,
						selectedState: _find("United States" === result.data.personmailingcountry ? this.states : this.provinces, { text: result.data.personmailingstate }) || null
					})
					window.scrollTo({ top: 0, behavior: 'smooth' });
				}
			}).catch((err) => {
				console.log(err)
			})
		}
	}

	render() {
		const pg = this.props.pageContext.page
		const attached = pg.attachments.filter((att) => { return att.slug === 'thank-you-mailing-preferences' })
		var thankyou = null
		if (attached.length > 0) {
			thankyou = attached[0]
		} else {
			throw new Error("thank-you-mailing-preferences is not attached")
		}

		return (
			<NewLayout>

				{pg.seo.title && <h1 style={{ display: 'none' }}>{pg.seo.title}</h1>}
				<SEO page={pg} />
				<div className='d-flex flex-column flex-fill'>
					<div className={styles.container}>
						<>
							<div className={cn(styles.formItems, "row")}>
								<div className={styles.formContainer}>
									{

										this.state.submitted ?
											<>
												<PageContents content={thankyou.content} />
												<div
													className={styles.thankYouCtas}
												>
													<Button
														blue={true}
														className={styles.buttonThanks}
														text="PRODUCTS"
														url={"/products"}
													/>
													<Button
														blue={true}
														className={styles.buttonThanks}
														text="BLOG"
														url={"/blog"}
													/>
													<Button
														blue={true}
														className={styles.buttonThanks}
														text="NEXT-LEVEL ADVICE"
														url={"/nextleveladvice"}
													/>
												</div>
											</>

											:

											<>
												{
													this.state.subscriberKey === null ?
														<>
															Subscriber Key Missing or Invalid
														</>
														:
														<>
															{
																this.state.subscriber === null ?
																	<>
																		Loading Subscriber Data...
																	</>
																	:
																	<>
																		{
																			this.state.subscriber === undefined ?
																				<>
																					Subscriber Not Found
																				</>
																				:
																				<>
																					{
																						this.state.processing ?
																							<>
																								Processing...
																							</>
																							:
																							<>
																								<PageContents content={pg.content} />
																								<Formik
																									initialValues={this.initialValues}
																									validate={values => {
																										var errors = []
																										if (values.firstname === "") {
																											errors.push({ field: "firstname", message: "First Name is required" })
																										}
																										if (values.lastname === "") {
																											errors.push({ field: "lastname", message: "Last Name is required" })
																										}
																										if (values.email === "") {
																											errors.push({ field: "email", message: "Email is required" })
																										} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
																											errors.push({ field: "email", message: "Invalid email address" })
																										}
																										if (values.phone === "") {
																											errors.push({ field: "phone", message: "Phone Number is required" })
																										}
																										if (values.occupation__pc === "") {
																											errors.push({ field: "occupation__pc", message: "Occupation is required" })
																										}
																										return errors
																									}}
																									onSubmit={(values, { setSubmitting }) => {
																										this.setState({
																											processing: true
																										}, () => {
																											window.scrollTo({ top: 0, behavior: 'smooth' });

																											if (values.personmailingcountry === "United States") {
																												let state = _find(this.states, { value: values.personmailingstate });
																												values.personmailingstate = state !== undefined ? state.text : "";
																											}

																											this.hobbies.forEach((hobby) => {
																												values[hobby.field] = this.state.hobbiesForm.indexOf(hobby.value.replace(/\s/g, "")) > -1 ? "True" : (this.state.subscriber[hobby.field] === "" ? "" : "False");
																											});
																											values.hobbies = undefined;

																											this.lists.forEach((list) => {
																												values[list.field] = this.state.listsForm.indexOf(list.id) > -1 ? "True" : (this.state.subscriber[list.field] === "" ? "" : "False");
																											});
																											values.lists = undefined;

																											values.opt_in = !values.optOut;
																											values.optOut = undefined;

																											const [year, month, day] = values.personbirthdate.split('-').map(Number);
																											const dateObject = new Date(year, month - 1, day);

																											if (!_isDate(dateObject)) {
																												values.personbirthdate = dateObject;
																											}

																											setEmailPreferences(this.state.subscriberKey, values).then((result) => {
																												window.scrollTo({ top: 0, behavior: 'smooth' });
																												this.setState({
																													processing: false,
																													submitted: true
																												})
																											})
																										})
																									}}>
																									{(props) => (
																										<form className={styles.form} onSubmit={props.handleSubmit}>
																											<div>
																												<div className={styles.row}>
																													<Input
																														name="firstname"
																														title="First Name*"
																														placeholder=""
																														className={styles.input}
																														onChange={props.handleChange}
																														onBlur={props.handleBlur}
																														value={props.values.firstname}
																													/>
																													<Input
																														name="lastname"
																														title="Last Name*"
																														placeholder=""
																														className={styles.input}
																														onChange={props.handleChange}
																														onBlur={props.handleBlur}
																														value={props.values.lastname}
																													/>
																												</div>
																												<div className={styles.row}>
																													<Input
																														name="email"
																														title="YOUR EMAIL ADDRESS*"
																														placeholder=""
																														className={styles.input}
																														onChange={props.handleChange}
																														onBlur={props.handleBlur}
																														value={props.values.email}
																														disabled={true}
																													/>
																													<Input
																														type='date'
																														name="personbirthdate"
																														title="Birthday"
																														placeholder="MM/DD/YYYY"
																														className={styles.input}
																														onChange={props.handleChange}
																														onBlur={props.handleBlur}
																														value={props.values.personbirthdate}
																													/>
																												</div>
																												<div className={styles.row}>
																													<Input
																														name="phone"
																														title="Phone Number"
																														phone="true"
																														placeholder="(000) 000-0000"
																														className={styles.input}
																														onChange={props.handleChange}
																														onBlur={props.handleBlur}
																														value={props.values.phone}
																													/>
																												</div>
																												<div className={styles.rowSelects}>
																													<div className="col-sm-4" style={{ paddingLeft: '0px' }}>
																														<Select
																															title="Country"
																															titleClassName={styles.selectTitle}
																															noMargin
																															className={styles.selectInRow}
																															placeholder='Select'
																															values={this.countries}
																															selected={props.values.personmailingcountry}
																															onBlur={props.handleBlur}
																															value={props.values.personmailingcountry}
																															onChange={(e) => {
																																this.setState({
																																	selectedCountry: _find(this.countries, { value: e.value }),
																																}, () => {
																																	props.setFieldValue('personmailingcountry', e.value)
																																})
																															}}
																														/>
																													</div>

																													<div className="col-sm-4" style={{ paddingLeft: '0px' }}>
																														{
																															this.state.selectedCountry &&
																																("United States" === this.state.selectedCountry.text ||
																																	"Canada" === this.state.selectedCountry.text) ?
																																<Select
																																	title={"United States" === this.state.selectedCountry.text ? "State" : "Provinces"}
																																	titleClassName={styles.selectTitle}
																																	noMargin
																																	className={styles.selectInRow}
																																	placeholder='Select'
																																	values={"United States" === this.state.selectedCountry.text ? this.states : this.provinces}
																																	selected={this.state.selectedState !== null ? this.state.selectedState.value : ''}
																																	onBlur={props.handleBlur}
																																	value={props.values.personmailingstate}
																																	onChange={(e) => {
																																		this.setState({
																																			selectedState: _find("United States" === this.state.selectedCountry.text ? this.states : this.provinces, { value: e.value }),
																																		}, () => {
																																			props.setFieldValue('personmailingstate', e.value)
																																		})
																																	}}
																																/>
																																:
																																<Input
																																	style={{ width: '100%', marginTop: '-6px' }}
																																	name="personmailingstate"
																																	title='State*'
																																	className={styles.input}
																																	onChange={props.handleChange}
																																	onBlur={props.handleBlur}
																																	value={props.values.personmailingstate} />

																														}


																													</div>
																													<div className="col-sm-4" style={{ padding: '0px', paddingRight: '0px' }}>
																														<Input
																															style={{ width: '100%' }}
																															name="personmailingpostalcode"
																															title='Postal Code'
																															className={styles.input}
																															onChange={props.handleChange}
																															onBlur={props.handleBlur}
																															value={props.values.personmailingpostalcode} />
																													</div>

																												</div>
																											</div>
																											<div>
																												<div className={styles.checkboxesRow}>
																													<Title
																														value="HOBBIES"
																														className={
																															styles.titleCheckbox
																														}
																													/>
																													{
																														this.state.hobbiesForm &&
																														<FieldArray name="hobbies" render={arrayHelpers => (
																															this.hobbies.map((item, index) => {
																																const checkboxValue = this.state.hobbiesForm.includes(item.value.replace(/\s/g, ""));
																																return (
																																	<Checkbox
																																		key={index}
																																		className={styles.checkboxCustomContainer}
																																		name={'hobbies'}
																																		text={item.value}
																																		value={checkboxValue}
																																		useOnlyValue={true}
																																		onClick={(checked) => {
																																			const inputValues = this.state.hobbiesForm;
																																			let filteredValues;
																																			let status = inputValues.includes(item.value.replace(/\s/g, ""))
																																			if (status) {
																																				filteredValues = inputValues.filter((element) => element !== item.value.replace(/\s/g, ""));
																																				status = false;
																																			} else {
																																				filteredValues = inputValues;
																																				filteredValues.push(item.value.replace(/\s/g, ""))
																																				status = true;
																																			}
																																			this.setState({
																																				hobbiesForm: filteredValues,
																																			})
																																		}}
																																	/>
																																)
																															})


																														)} />
																													}

																												</div>
																												<div className={cn(styles.feedback)}>
																													<ul>
																														{_isArray(props.errors) && props.errors.filter((f) => { return props.touched[f.field] }).map((e) => {
																															return <li key={e.field}>{e.message}</li>
																														})}
																													</ul>
																												</div>
																												<div className={cn(styles.rowLast)}>
																													<Button
																														text="Submit"
																														newBlue
																														submit
																														className={styles.input}
																													/>

																												</div>
																												<div className={cn(styles.rowLast)}>
																													<span className={styles.contentText}>
																														*View our <a href="/privacy">Privacy Policy</a> and <a href="/terms-of-use">Terms of Use</a>
																													</span>
																												</div>
																											</div>
																										</form>
																									)}
																								</Formik>
																							</>
																					}
																				</>
																		}
																	</>
															}
														</>
												}
											</>
									}
								</div>
							</div>
						</>
					</div>
				</div>
			</NewLayout>
		);
	}
}