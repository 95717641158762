import axios from "axios"
import { isBrowser } from "./util"

export const setEmailPreferences = (subscriberKey, values) => {
    values['subscriber_key'] = subscriberKey;
    return new Promise(function(resolve, reject) {
        if(!isBrowser()){
            reject("Do not do this from Node!")
        }else{
            axios.post(
                `https://9lmkm7lalj.execute-api.us-west-2.amazonaws.com/default/emailPreferencesSet`,
                values,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            ).then((result) => {
                resolve(result)
            }).catch((err)=>{
                reject(err)
            })
        }
    })
}