import axios from "axios"
import { isBrowser } from "./util"

export const getEmailPreferences = (subscriberKey) => {
    return new Promise(function(resolve, reject) {
        if(!isBrowser()){
            reject("Do not do this from Node!")
        }else{
            axios.get(
                `https://bw2kswpr9l.execute-api.us-west-2.amazonaws.com/default/emailPreferencesGet?SubscriberKey=${subscriberKey}`
            ).then((result) => {
                resolve(result)
            }).catch((err)=>{
                reject(err)
            })
        }
    })
}